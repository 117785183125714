@import 'variable';

.saisie {
  height: 100%;
  position: relative;
  display: grid;
  .nav-tabs {
    font-size: 14px;

    .nav-item {
      max-width: 33%;
      border: 1px solid $pnb_grey;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
    }

    .active {
      background-color: $pnb_grey;
    }
  }

  .form_saisie {
    height: calc(100vh - 300px);
    overflow: auto;
    //margin-bottom: 70px;
  }

  #alert_save_saisie, #alert_danger_save_saisie{
    width: 100%;
    position: absolute;
    display: none;
  }
  form {

    ul {
      padding: 0;

      li {
        list-style-type: none;
        margin: 20px 0 10px 20px;
        font-size: 14px;

        div {
          display: flex;

          label {
            display: inline-block;
            width: 60%;
            text-align: left;
            margin: 0 20px 0 0;
          }

          .bold {
            font-weight: bold;
          }

          input {
            background-color: $pnb_light_grey;
            border: 1px solid $pnb_border;
            border-radius: .25rem;
          }

          input[type='number'] {
            -moz-appearance: textfield;
          }

          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
          }
        }
      }
    }
  }

  .indicateur_multiple{
    display: none;
    height: 10%;
    li{
      margin-left: 0;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    label{
      margin: 0;
    }
    input{
      display: inline-flex;
    }
  }
  .indicateur_multiple_moins{
    display: none;
    height: 10%;
  }

}

.modal-text{
  font-size: medium;
}

.button_form {
  border-top: 1px solid;
  position: sticky;
  background-color: white;
  max-width: 100%;
  display: grid;
  height: 100%;
  .saisie_legend{
    margin: 15px;
    position: absolute;
  }
  .div_button{
    display: inline;
    position: relative;
    height: 100%;
    button {
      margin-left: 20px;
      height: inherit;
      width: 170px;
      float: right;
    }
  }
}
