@import 'variable';


.card {
  margin-top: 10px;
  margin-bottom: 40px;

  .card-header {
    background-color: $pnb_blue;
    color: white;

    h5 {
      margin: 0;
    }
  }

  .card_form {
    text-align: center;

    label {
      float: left;
      margin: 10px;
    }

    button {
      margin-top: 10px;
      float: left;
    }

    a {
      margin-top: 10px;
      float: left;
      margin-left: 10px;
    }

    .login_button {
      margin-top: 15px;
    }

    .forgot_password {
      float: right;
      margin-top: 60px;
    }

    .cgu-checkbox {
      display: inline-flex;
      margin-top: 15px;
      width: 100%;

      a {
        float: right;
        margin-top: 0;
        margin-left: 5px;
      }

      label {
        margin: 0 0 0 10px;
      }

      .form-check {
        position: relative;
        vertical-align: middle;
        bottom: 2px;

        input {
          position: unset;
        }
      }

      .form-check-label {
        display: none;
      }
    }

    .ask-account-btn {
      float: right;
      margin-top: 20px;
    }

  }
}


.before_login_background {
  background: url(../../images/Batiments_login.png) bottom right no-repeat fixed;
  width: 100%;
  min-height: calc(100% - 3.75rem);
  position: absolute;

  .title {
    padding-top: 20px;
    font-size: 16px;
  }
}

.register_form {
  width: 33%;
  margin-top: 5%;

  input {
    margin: 5px 0px;
  }
}


.compte {
  margin-top: 20px;
  font-family: $font;
  text-align: center;
  border: 1px solid black;
  border-radius: .25rem;
  padding: 0;
  background-color: white;
  margin-bottom: 40px;

  h5 {
    text-align: left;
    background-color: $pnb_blue;
    color: white;
    font-size: 17px;
    padding: 20px;
  }

  .compte_item {
    width: auto;
    margin-bottom: 10px;

    label {
      width: 15%;
      text-align: left;
    }

    .input_disabled {
      height: 40px;
      background-color: $pnb_grey;
      border: 1px solid $pnb_border;
      border-radius: .25rem;
      color: rgb(73, 80, 87);
      width: 75%;
      padding: 10px;
    }
  }

  .fieldset {
    margin: 30px;
    border: 1px groove rgb(221, 221, 221);

    legend {
      font-size: 17px;
      width: auto;
      margin-left: 25px;
      padding: 0 10px;
      font-weight: 700;
    }

    p {
      padding: 10px;
      text-align: left;
      font-size: 0.9em;
    }

    label {
      width: 20%;
      text-align: left;
    }

    input {
      height: 40px;
      border: 1px solid $pnb_border;
      border-radius: .25rem;
      background-color: $pnb_light_grey;
      color: rgb(73, 80, 87);
      width: 75%;
      padding: 10px;
      display: inline-block;
    }

    .form_change_password_button {
      float: right;
      margin-right: 20px;

      div {
        display: inline-block;
      }

      a {
        display: inline-block;
      }
    }
  }
}
