$body-color: rgb(243, 243, 243);
$pnb_blue: #2a5f7c;
$pnb_grey: rgb(189, 189, 189);
$pnb_light_grey: rgb(245, 245, 245);
$pnb_border: rgb(158, 158, 158);
$font: Tahoma, sans-serif;
$button_color: rgb(153, 134, 119);
$footer_text_color: #212529;


$vert: #61a057;
$orange: #ff9a18;
$grey: #bdbdbd;
