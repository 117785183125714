@import 'variable';

.footer {
  width: 100%;
  height: 30px;
  background: white;
  position: fixed;
  bottom: 0;

  .footer_right {
    margin-right: 40px;

    .nav-item {
      cursor: pointer;
      a{
        color: $footer_text_color;
      }
    }
    .footer_version{
      cursor: default;
    }
  }

  .footer_bar {
    margin: 3px 10px;
    border: thin #1d2124 solid;
  }
}

.mentions_legales{
  iframe{
    height: calc(100vh - 100px);
    width:100%;
  }
}
