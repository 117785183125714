@import 'variable';


.list_utilisateur{
  .list_user_paragraph{
    margin-left: 20px;
  }
  h4{
    padding: 20px;
  }
  .btn{
    margin: 20px;
  }
}

.form_admin{
  margin: 20px;
  .form-check-label{
    vertical-align: sub;
  }
  .form_admin_button{
    div{
      display: inline-block;
    }
    a{
      display: inline-block;
    }
  }
}

.navigation_line {
  display: block;
  border-bottom: thin solid black;
  margin-bottom: 10px;
}

.navigation_admin{
  ul{
    margin-top: 20px !important;
    li{
      margin: 10px 0;
    }
  }
  .form-group{
    margin: 0;
  }
}
