@import 'variable';

.content {
  height: 100%;
  position: relative;
  width: 80%;
  padding: 0;
  border: 1px solid #333;
  background-color: white;

  .title_accueil {
    font-size: 1.20rem;
    color: white;
    background-color: $pnb_blue;
    padding: 20px;

    p {
      display: inline;
    }

    p:last-of-type {
      float: right;
    }
  }

  .last_month_accueil {
    font-size: 15px;
    margin-top: 2rem;
    text-align: center;

    p {
      margin: 20px;
    }

    table {
      margin: auto;

      th {
        width: auto;
        padding: 10px;
      }

      td {
        width: auto;
        padding: 10px;
      }
    }

    .etat_complet {
      background-color: $vert;
    }

    .etat_incomplet {
      background-color: $orange;
    }

    .etat_vide {
      background-color: $grey;
    }
  }

  .content_subtitle {
    background-color: $pnb_blue;
    color: white;
    margin: 20px 0;
    padding: 10px;
    font-size: 15px;
  }
}

.content_title {
  background-color: $pnb_blue;
  color: white;
  padding: 20px;
}

.legend {
  margin-top: 5px;
  margin-left: 5px;

  .item {
    display: inline-block;

    span {
      width: 20px;
      height: 20px;
      display: inline-block;
    }

    p {
      display: inline-block;
      margin: 0;
      vertical-align: super;
    }

    .orange {
      background-color: $orange;
    }

    .vert {
      background-color: $vert;
    }

    .grey {
      background-color: $grey;
    }
  }
}

.accueil_structure {
  text-align: center;
  margin: 40px;
  font-size: 24px;

  .form-group {
    display: inline-block;
  }

  .form-group:nth-of-type(1) {
    width: 33%;
  }

  .form-group:nth-of-type(2) {
    margin-left: 10px;
    vertical-align: bottom;
  }
}

#bs-select-1{
  ul{
    text-align: left;
  }
}

