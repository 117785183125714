@import 'variable';

html,
body {
  height: 100%;
}

body {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $body-color;
  font-family: $font;
  font-size: 0.8em;
  position: relative;
}

.btn-primary {
  border: 1px solid $button_color;
  background-color: $button_color;
}

.header {
  height: 60px;
  font-size: 16px;


  .header-logo {
    cursor: pointer;

    img {
      height: 3.375rem;
      width: auto;
    }
  }

  .logo-CEE{
    cursor: default;
  }


  .navbar {
    height: 100%;
    text-align: center;
    background-color: white !important;

    ul {
      display: flex;
      align-items: center;
    }
    
    .separateur {
      border-left: 1px solid #dddddd;
      height: 25px;
      margin-left: 5px;
    }
  }
}

table {
  margin: 10px;

  .table_indicateur {
    width: 20%;
    font-size: 14px;
  }

  .table_month {
    font-size: 14px;
  }

  .table_mission {
    td {
      height: 40px;
      font-weight: bold;
      background-color: $pnb_blue;
      color: white;
    }
  }

  .table_month {
    width: 4%;
  }

  .line_table {
    font-size: 13px;
  }

  .line_table:nth-child(odd) {
    background-color: $pnb_grey;
  }

  .total {
    font-weight: bold;
  }

  .calcul {
    font-weight: bold;
  }

  .col_mission {
    background-color: white;
  }

  .type_calcul {
    font-weight: bold;
  }

  td,
  th {
    border: 2px solid #333;
    text-align: center;
  }
}

.main {
  margin-bottom: 40px;
  height: 90%;
  .actionPanel {
    background-color: white;
    margin-right: 50px;
    border: solid 1px;
    margin-left: 20px;
    padding: 0;
    height: 65%;
    display: inline-table;
    .title {
      font-size: 1.50rem;
      color: white;
      background-color: #2a5f7c;
      padding: 20px;
      margin: 0;
      text-align: center;
    }

    h1 {
      text-align: center;
      border-bottom: solid 1px;
      margin: 0;
      padding: 1em;
    }

    form {
      .datepicker,
      .table-condensed {
        background-color: $pnb_light_grey;
        width: 100%;

        .month_green {
          background-color: green;
        }

        .month_orange {
          background-color: orange;
        }
        .next, .prev{
          width: 75px;
          height: 30px;
          font-size: x-large;
        }
      }

      div {
        .icon {
          display: flex;
          align-items: center;
        }
      }
    }

    ul {
      list-style: none;
      text-align: center;
      margin-top: 20px;
      padding: 0;
      li {
        padding: 5px;
      }
      button, .btn {
        width: 100%;
        margin: 5px 0px;
      }
    }
    .form_navigation_user{
      .bootstrap-select{
        max-width: 300px;
      }
    }
  }
}
