@import 'variable';

.consultation {
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 98%;
  min-width: 100%;

  .consultation_action {
    margin-top: 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .change_year_consultation {
      display: inline-block;
      width: auto;
      font-size: 20px;
      text-align: center;
    }

    .btn_export {
      float: right;
      display: inline-block;
      position: absolute;
      right: 5%;

      .form-group {
        margin: 0;
      }

      form {
        display: inline-block;
      }
    }
  }

  .table_consultation {
    overflow-y: auto;

    .consultation_legend {
      margin: 15px;
    }
  }
}

.filter_border {
  form {
    border: black 1px solid;

    .restitution_filter {
      display: table;

      legend {
        display: none;
      }

      .dropdown-toggle {
        background-color: white;
        border: 1px solid #ced4da;
      }

      .restitution_filter_structure {
        padding: 10px;
        margin: 0 auto;


        .structure_filter_national {
          display: inline-block;
          margin: 5px;
          width: 48%;

          .bootstrap-select {
            width: 100%;
          }
        }

        .structure_filter_multiple {
          width: 60%;

          label {
            margin-top: 5px;
          }

          .dropdown-toggle {
            background-color: white;
            border: 1px solid #ced4da;
          }
        }

        .restitution_filter_Structure_regional {
          width: 120%;
        }
      }

      .restitution_filter_datepicker {
        border-right: black 1px solid;
        border-left: black 1px solid;
        width: 30%;
        padding: 10px;
        display: table-cell;
        text-align: center;
        vertical-align: middle;
        #label-datepicker {
          display: block;
          text-align: left;
          margin-top: 5px;
        }
        .restitution_filter_datepicker_input{
          margin-bottom: 15px;
        }



        .form-check {
          text-align: left;
          margin-left: 5px;


          input{
            position: relative;
            vertical-align: middle;
            bottom: 3px;
          }

          .form-check-label {
            margin-left: 5px;
          }
        }

        button {
          width: 35%;
          display: inline-block;
          vertical-align: initial;
        }

        .restitution_filter_datepicker_input {
          text-align: center;

          input {
            width: 49%;
            display: inline-block;
            margin-top: 0;
          }
        }

        .restitution_filter_datepicker_label {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        #reversePeriod, #oversizedPeriod {
          display: none;
        }
      }

      .restitution_filter_apply {
        border-right: black 1px solid;
        display: flex;
        align-items: center;
        padding: 10px;

        button {
          width: 100%;
          margin: 10px;
        }
      }

      .restitution_filter_export {
        vertical-align: middle;
        display: flex;
        align-items: center;


        button {
          width: 100%;
        }

        .btn-primary:disabled {
          color: lightgray;
          background: #998677;
          border: #998677;
        }
      }
    }
  }
}

.restitution {
  background-color: white;
  position: absolute;
  display: flex;
  flex-direction: column;
  height: 90%;
  min-width: 100%;

  .table_restitution {
    margin-top: 20px;
    height: 100%;
    overflow: auto;

    .table_restitution_legend {
      border: none;
      padding: 8px 0px 10px 10px;
    }

  }
}

#no_result {
  margin-top: 30px;
  font-size: large;
}

.line_table_restitution_obligatoire {
  background-color: white;
}

.line_table_restitution {
  background-color: $pnb_grey;
}
